// グローバル変数
var timer = false;
var slideFlg = 0;
var bp = 767; // ブレイクポイント
var speed = 500; // アニメーションスピード
var resizeTimer, btnGrandHeight, ftrPagetopPosMN;

$(function () {
  var pagetop = $(".pagetop");
  var banner = $(".global-banner");

  var recipeListItem = $(".index-recipe-list-item");
  var recipeListItemLength = recipeListItem.length;
  var recipeFilterBtn = ".recipe-category-list-item__num";
  var searchBox = ".recipe-category-list";
  var listItem = ".index-recipe-list-item";
  var hideClass = "is-hide";
  var shaka = 0;
  var momi = 0;
  var para = 0;
  var kake = 0;
  var plus = 0;
  var meet = 0;
  var fish = 0;
  var vegetable = 0;
  var rice = 0;
  var noodle = 0;
  var soup = 0;
  var other = 0;
  var kaketemo = 0;
  var mazetemo = 0;
  var konominoryode = 0;
  var osusume = 0;

  // // ローディング
  // pageLoading();

  // // ページロード完了後
  // $(window).on("load",pageLoadComplete);

  // ドロワー開閉
  $(".nav-trigger").on("click", switchDrawer);

  // 初期表示
  search_filter();

  // ページ内スムーススクロール
  $(document).on("click", 'a[href^="#"]', function () {
    var spd = speed;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? "html" : href);
    var position = target.offset().top;
    $("html, body").animate({ scrollTop: position }, spd, "swing");
    if ($("html").hasClass("active")) {
      $("html").removeClass("active");
      $("#overlay").remove();
    }
    return false;
  });

  // スクロール時、メニューが開いていたら閉じる
  $(window).on("scroll", function () {
    if ($("html").hasClass("active")) {
      $("html").removeClass("active");
    }
  });

  // // loading
  // function pageLoading() {
  // 	$("body").append('<div id="loading"></div>');
  // 	$("html").addClass("scrollLock");
  // }
  // function pageLoadComplete() {
  // 	$("#loading").fadeOut(300);
  // 	$("html").removeClass("scrollLock");
  // }

  // ドロワー開閉
  function switchDrawer() {
    if ($("html").hasClass("active")) {
      $("#overlay").remove();
    } else {
      $("body").append('<div id="overlay">');
    }
    $("html").toggleClass("active");
    return false;
  }

  // remodal
  $(document).on("closed", ".remodal", function (e) {
    history.replaceState("", "", window.location.pathname);
  });

  $(window).on("load", function () {
    var theTime = Date.now();
    var theData = sessionStorage.getItem("visitedTime");
    var timeThrough = 0;

    // console.log("pre: "+theData);
    // console.log("Now: "+theTime);

    // 30分ごとにモーダルを表示
    if (theData === null) {
      sessionStorage.setItem("visitedTime", theTime);
      var inst = $("[data-remodal-id=first]").remodal();
      inst.open();
    } else {
      timeThrough = theTime - theData;
      if (timeThrough > 1800000) {
        sessionStorage.setItem("visitedTime", theTime);
        // console.log("30minutes passed: "+sessionStorage.getItem('visitedTime'));
        var inst = $("[data-remodal-id=first]").remodal();
        inst.open();
      }
    }
  });

  $(window).on("load scroll", function () {
    if ($("body").hasClass("top")) {
      // トップページのみbodyに下マージンを付与
      btnGrandHeight = $(".btn-grand").height();
      $("body.top").css({ "margin-bottom": btnGrandHeight + "px" });

      if (location.hash != "#recipe") {
        var $currentpath = window.location.pathname
          .replace("//", "")
          .replace(/./, "")
          .replace(/_/, "-"); // 表示中のページのファイル名を取得する
        if (!$currentpath) {
          $currentpath = "homepage";
        } // トップページ（ファイル名が存在しない場合）に登録する任意のファイル名
        if (window.name.indexOf("#" + $currentpath) != -1) {
          //ウィンドウ名に現在のファイル名が含まれるかチェック
        } else {
          window.name = window.name + "#" + $currentpath; // ウィンドウ名の末尾にファイル名を追加する、#はエスケープ処理
          // var inst = $("[data-remodal-id=first]").remodal();
          // inst.open();
        }
      }
    } else {
      btnGrandHeight = 0;
    }

    // ページトップ
    var nowPosition = $(window).height() + $(window).scrollTop();
    var ftrHeight = $(".global-footer").innerHeight();
    if ($(this).scrollTop() > 0) {
      pagetop.addClass("show");
    } else {
      pagetop.removeClass("show");
    }
    // if ($(document).height() - nowPosition <= ftrHeight) {
    //   if (window.innerWidth > bp) {
    //     ftrPagetopPosMN = 20;
    //   } else {
    //     ftrPagetopPosMN = -20;
    //   }
    //   pagetop.css({
    //     position: "absolute",
    //     bottom: ftrHeight + ftrPagetopPosMN,
    //   });
    // } else {
    //   pagetop.css({
    //     position: "fixed",
    //     bottom: btnGrandHeight + 20 + "px",
    //   });
    // }

    // バナー
    if ($(this).scrollTop() > 0) {
      banner.addClass("show");
    } else {
      banner.removeClass("show");
    }
    if ($(document).height() - nowPosition <= ftrHeight) {
      banner.css({
        position: "absolute",
        bottom: ftrHeight,
      });
    } else {
      banner.css({
        position: "fixed",
        bottom: 0,
      });
    }

    // グランプリバナー
    var mvBtn = $(".main-visual__btn");
    if (mvBtn[0]) {
      var mbBtnPos = mvBtn.offset().top + mvBtn.height();
      if ($(window).scrollTop() >= mbBtnPos) {
        $(".btn-grand").addClass("show");
      } else {
        $(".btn-grand").removeClass("show");
      }
    }
  });

  $(window).on("load resize", function () {
    timer = setTimeout(function () {
      // 他のレシピ 矢印の位置を画像の高さに合わせる
      $(".recipe-list").each(function () {
        var recipeImgPos =
          $(this).find(".recipe-list-item__thumb").height() / 2;
        $(this).children(".slick-prev,.slick-next").css({ top: recipeImgPos });
      });
    }, 200);
  });

  // アニメーション
  $(window).on("load scroll", function () {
    lazyLoad();
  });

  function lazyLoad() {
    var magicnumber = 0;
    var windowHeight = $(window).height();
    $(".is-lazy-show").each(function (e) {
      var delayFlg = 0;

      var targetPos = $(this).offset().top;
      var scrollPos = $(window).scrollTop();
      if (scrollPos > targetPos - windowHeight + magicnumber) {
        $(this)
          .delay(delayFlg)
          .queue(function () {
            $(this).addClass("is-animate-show");
          });
      }
    });
  }

  // slider
  if ($(".main-visual-slide")[0]) {
    $(".main-visual-slide").slick({
      autoplay: true,
      slidesToShow: 1,
      fade: true,
      arrows: false,
      autoplaySpeed: 2000,
      speed: 1500,
      touchMove: false,
      pauseOnFocus: false,
      pauseOnHover: false,
    });
  }
  if ($(".index-dashiwaza-list")[0]) {
    // if (window.innerWidth < bp) {
    $(".index-dashiwaza-list").slick({
      autoplay: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
    // }
  }
  if ($(".recipe-list")[0]) {
    $(".recipe-list").slick({
      autoplay: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    });
  }

  // アコーディオン
  $(".accordion__trigger").on("click", function () {
    $(this).parent().next(".accordion__body").stop().slideToggle();
  });
  $(".accordion__close").on("click", function () {
    $(this).parents(".accordion__body").stop().slideToggle();
  });

  // リストをランダム表示
  // if ($(".index-recipe-list")[0]) {
  // 	shuffleContent($(".index-recipe-list"));
  // }

  // レシピの数をカテゴリごとに取得して代入する
  $(".index-recipe-list-item").each(function () {
    var arrTag = $(this).data("tag");
    var tag = arrTag[0];
    var tag02 = arrTag[1];
    var tag03 = arrTag[2];
    switch (tag) {
      case "shaka":
        shaka += 1;
        break;
      case "momi":
        momi += 1;
        break;
      case "para":
        para += 1;
        break;
      case "kake":
        kake += 1;
        break;
      case "plus":
        plus += 1;
        break;
      case "meet":
        meet += 1;
        break;
      default:
    }
    switch (tag02) {
      case "meet":
        meet += 1;
        break;
      case "fish":
        fish += 1;
        break;
      case "vegetable":
        vegetable += 1;
        break;
      case "rice":
        rice += 1;
        break;
      case "noodle":
        noodle += 1;
        break;
      case "soup":
        soup += 1;
        break;
      case "other":
        other += 1;
        break;
      default:
    }
    switch (tag03) {
      case "kaketemo":
        kaketemo += 1;
        break;
      case "mazetemo":
        mazetemo += 1;
        break;
      case "konominoryode":
        konominoryode += 1;
        break;
      default:
    }
    if (arrTag[3] == "osusume") {
      osusume += 1;
    }
  });
  $(recipeFilterBtn).text("(" + recipeListItemLength + ")");
  $(recipeFilterBtn + ".shaka").text("(" + shaka + ")");
  $(recipeFilterBtn + ".momi").text("(" + momi + ")");
  $(recipeFilterBtn + ".para").text("(" + para + ")");
  $(recipeFilterBtn + ".kake").text("(" + kake + ")");
  $(recipeFilterBtn + ".plus").text("(" + plus + ")");
  $(recipeFilterBtn + ".meet").text("(" + meet + ")");
  $(recipeFilterBtn + ".fish").text("(" + fish + ")");
  $(recipeFilterBtn + ".vegetable").text("(" + vegetable + ")");
  $(recipeFilterBtn + ".rice").text("(" + rice + ")");
  $(recipeFilterBtn + ".noodle").text("(" + noodle + ")");
  $(recipeFilterBtn + ".soup").text("(" + soup + ")");
  $(recipeFilterBtn + ".other").text("(" + other + ")");
  $(recipeFilterBtn + ".kaketemo").text("(" + kaketemo + ")");
  $(recipeFilterBtn + ".mazetemo").text("(" + mazetemo + ")");
  $(recipeFilterBtn + ".konominoryode").text("(" + konominoryode + ")");
  $(recipeFilterBtn + ".osusume").text("(" + osusume + ")");

  // レシピ絞り込み

  // 絞り込み項目を変更した時
  $(document).on("change", searchBox + " input", function () {
    search_filter();
    lazyLoad();
  });

  /**
   * リストの絞り込みを行う
   */
  function search_filter() {
    // 非表示状態を解除
    $(listItem).removeClass(hideClass);
    for (var i = 0; i < $(searchBox).length; i++) {
      var name = $(searchBox).eq(i).find("input").attr("name");
      // 選択されている項目を取得
      var searchData = get_selected_input_items(name);
      // 選択されている項目がない、またはALLを選択している場合は飛ばす
      if (searchData.length === 0 || searchData[0] === "") {
        continue;
      }
      // リスト内の各アイテムをチェック
      for (var j = 0; j < $(listItem).length; j++) {
        // アイテムに設定している項目を取得
        var itemData = get_setting_values_in_item($(listItem).eq(j), name);
        // 絞り込み対象かどうかを調べる
        var check = array_match_check(itemData, searchData);
        if (!check) {
          $(listItem).eq(j).addClass(hideClass);
        }
      }
    }
  }

  /**
   * inputで選択されている値の一覧を取得する
   * @param  {String} name 対象にするinputのname属性の値
   * @return {Array}       選択されているinputのvalue属性の値
   */
  function get_selected_input_items(name) {
    var searchData = [];
    $("[name=" + name + "]:checked").each(function () {
      searchData.push($(this).val());
    });
    return searchData;
  }

  /**
   * リスト内のアイテムに設定している値の一覧を取得する
   * @param  {Object} target 対象にするアイテムのjQueryオブジェクト
   * @param  {String} data   対象にするアイテムのdata属性の名前
   * @return {Array}         対象にするアイテムのdata属性の値
   */
  function get_setting_values_in_item(target, data) {
    var itemData = target.data(data);
    if (!Array.isArray(itemData)) {
      itemData = [itemData];
    }
    return itemData;
  }

  /**
   * 2つの配列内で一致する文字列があるかどうかを調べる
   * @param  {Array} arr1 調べる配列1
   * @param  {Array} arr2 調べる配列2
   * @return {Boolean}    一致する値があるかどうか
   */
  function array_match_check(arr1, arr2) {
    // 絞り込み対象かどうかを調べる
    var arrCheck = false;
    for (var i = 0; i < arr1.length; i++) {
      if (arr2.indexOf(arr1[i]) >= 0) {
        arrCheck = true;
        break;
      }
    }
    return arrCheck;
  }

  // 要素シャッフル
  function shuffleContent(container) {
    var content = container.find("> *");
    var total = content.size();
    content.each(function () {
      content.eq(Math.floor(Math.random() * total)).prependTo(container);
    });
  }

  // youtube
  $(document).on("closed", ".remodal-video", function () {
    var modal = $(this);
    modal.find("iframe").each(function () {
      var iframe = $(this).attr("id");
      videoControl("pauseVideo", iframe);
    });
  });

  function videoControl(action, obj) {
    var playerWindow = $("#" + obj)[0].contentWindow;
    playerWindow.postMessage(
      '{"event":"command","func":"' + action + '","args":""}',
      "*"
    );
  }
});
